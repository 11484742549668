import { Box, Card, InputAdornment, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from '../../assets/mui-styles/Colors';
import { handleOpenModelWarning } from '../../entities/ComponentsSlice';
import { actionLogging } from '../../utils/loggers';
import { toFormat } from '../../utils/utils';
import style from './Calculator.module.scss';
import { initialProjects } from './calculator-logic/initialProjects';
import { CustomAutocompleteInput } from './ui/CustomAutocompleteInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSelect } from './ui/CustomSelect';
import { CustomSwitch } from './ui/CustomSwitch';
import { NumberSumFormatCustom, PercentFormatCustom } from './ui/InputComponentsForInputWithSlider';

export const CalculatorForLot = ({
  activeProject,
  sections,
  handleChangeActiveProjectId,
  handleChangeSection,
  section,
  flats,
  flat,
  handleChangeFlat,
  activeProjectId,
  bulk,
  bulks,
  house,
  handleChangeBulk,
  handleChangeHouse,
  houses,
  benefit,
  benefits,
  handleChangeBenefit,
  handleChangeFirstPaymentCommitted,
  handleChangeFirstPayment,
  firstPayment,
  term,
  handleChangeTerm,
  handleChangeTermCommitted,
  isSetMinFirstPaymentChecked,
  handleSetMinFirstPayment,
  percentFirstPayment,
  isPercent,
  handleChangePercentValue,
  handleSwitchValue,
  maxFirstPayment,
  minFirstPayment,
  priceWithMargin,
  projectHasChanged,
  houseHasChanged,
  sectionOrBulkHasChanged,
  flatHasChanged,
  isEqualizer,
  currentBenefit,
  meetingId,
  maxTerm,
  minTerm,
  benefitNotFound,
  fetchStatus,
}) => {
  const [errorFirstPayment, setErrorFirstPayment] = useState('');
  const [errorTerm, setErrorTerm] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (firstPayment < minFirstPayment) {
      setErrorFirstPayment('Увеличьте сумму первого взноса');
    } else if (firstPayment > maxFirstPayment) {
      setErrorFirstPayment('Уменьшите сумму первого взноса');
    } else {
      setErrorFirstPayment('');
    }
  }, [firstPayment, maxFirstPayment, minFirstPayment]);

  useEffect(() => {
    if (term < minTerm) {
      setErrorTerm('Увеличьте срок кредита');
    } else if (term > maxTerm) {
      setErrorTerm('Уменьшите срок кредита');
    } else {
      setErrorTerm('');
    }
  }, [minTerm, maxTerm, term]);

  useEffect(() => {
    if (isEqualizer && currentBenefit) {
      actionLogging(`Перешли на программу "${currentBenefit.name}"  эквалайзера расчет по лоту`, meetingId);
    }
  }, [currentBenefit, isEqualizer, meetingId]);

  useEffect(() => {
    if (isEqualizer && currentBenefit && !currentBenefit?.mortgage) {
      dispatch(
        handleOpenModelWarning({
          open: true,
          text: 'По этой программе недостаточно данных. Выберите другую.',
        }),
      );
      handleChangeBenefit();
    }
  }, [currentBenefit, dispatch, isEqualizer]);

  useEffect(() => {
    if (benefitNotFound) {
      dispatch(
        handleOpenModelWarning({
          open: true,
          text: 'По этой программе недостаточно данных. Выберите другую.',
        }),
      );
    }
  }, [benefitNotFound]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        background: Colors.additional,
        border: '1px solid',
        borderColor: Colors.superLightGrey,
        borderTopColor: 'transparent',
      }}
      className={style.calculatorBlockContainer}
    >
      <Typography variant='h3' className={style.title}>
        Рассчитать платеж
      </Typography>
      <CustomSelect
        label='Выберите проект'
        items={initialProjects}
        currentItem={activeProject}
        handleChangeCurrentItem={value => {
          projectHasChanged();
          handleChangeActiveProjectId(value);
        }}
      />

      <Box
        sx={{
          display: 'flex',
          gap: '24px',
        }}
      >
        {houses.length > 1 && Number(activeProjectId) !== 1 && (
          <CustomAutocompleteInput
            label='Выберите дом'
            options={houses}
            value={house}
            onChange={value => {
              houseHasChanged();
              handleChangeHouse(value);
            }}
          />
        )}

        {bulks?.length > 0 && Number(activeProjectId) !== 1 && (
          <CustomAutocompleteInput
            label='Номер корпуса'
            options={bulks}
            value={bulk}
            onChange={value => {
              sectionOrBulkHasChanged();
              handleChangeBulk(value);
            }}
            error={flats?.length === 0 && 'В этом корпусе нет доступных квартир'}
          />
        )}

        {sections?.length > 0 && Number(activeProjectId) === 1 && (
          <CustomAutocompleteInput
            label='Номер секции'
            options={sections}
            value={section}
            onChange={value => {
              sectionOrBulkHasChanged();
              handleChangeSection(value);
            }}
            error={flats?.length === 0 && 'В этой секции нет доступных квартир'}
          />
        )}

        {flats?.length > 0 && (
          <CustomAutocompleteInput
            label='Номер квартиры'
            options={flats}
            value={flat}
            onChange={value => {
              flatHasChanged();
              handleChangeFlat(value);
            }}
          />
        )}
      </Box>

      {benefits?.length > 0 && flat && (
        <CustomSelect
          type='Программа'
          label='Выберите программу'
          handleChangeCurrentItem={value => handleChangeBenefit(value)}
          currentItem={benefit || ''}
          items={benefits}
        />
      )}
      {benefit &&
        flat &&
        (!isEqualizer || (isEqualizer && currentBenefit?.group === 'mortgage' && currentBenefit?.mortgage)) && (
          <>
            <Box
              sx={{
                display: 'grid',
                gap: '24px',
                gridTemplateColumns: '1fr 13vw',
              }}
            >
              <CustomInputWithSlider
                helperText={errorFirstPayment}
                error={firstPayment < minFirstPayment || firstPayment > maxFirstPayment}
                onChangeInput={isPercent ? handleChangePercentValue : handleChangeFirstPaymentCommitted}
                onChangeSlider={handleChangeFirstPayment}
                valueSlider={firstPayment}
                valueInput={isPercent ? percentFirstPayment : firstPayment}
                label='Сумма первоначального взноса'
                min={minFirstPayment}
                max={maxFirstPayment}
                minValueText={
                  isPercent
                    ? `${Math.round(((minFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                    : toFormat(minFirstPayment)
                }
                maxValueText={
                  isPercent
                    ? `${Math.round(((maxFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                    : toFormat(maxFirstPayment)
                }
                inputComponent={isPercent ? PercentFormatCustom : NumberSumFormatCustom}
                endAdornment={
                  <InputAdornment position='end'>
                    {isPercent ? toFormat(firstPayment) : `${percentFirstPayment} %`}
                  </InputAdornment>
                }
                onChangeCommitted={handleChangeFirstPaymentCommitted}
              />
              <CustomInputWithSlider
                onChange={handleChangeTerm}
                onChangeCommitted={handleChangeTermCommitted}
                onChangeInput={handleChangeTermCommitted}
                value={term}
                label='Срок кредита'
                min={minTerm}
                max={maxTerm}
                minValueText={minTerm}
                maxValueText={maxTerm}
                inputType='number'
                helperText={errorTerm}
                error={term < minTerm || term > maxTerm}
                fetchStatus={fetchStatus}
              />
            </Box>
            <Stack sx={{ alignItems: 'flex-start' }}>
              <CustomSwitch onClick={handleSwitchValue} checked={isPercent} label='Сумма первого взноса, %' />
              <CustomSwitch
                onClick={handleSetMinFirstPayment}
                checked={isSetMinFirstPaymentChecked}
                label='Установить минимальный первый взнос'
              />
            </Stack>
          </>
        )}
    </Card>
  );
};
