import { useEffect, useState, useCallback } from 'react';

export const useSelectTerm = (uniqueSuffix, maxYears, project) => {
  const termName = `currTerm${uniqueSuffix}`;
  const [term, setTerm] = useState(
    (sessionStorage.getItem(termName) !== 'undefined' && sessionStorage.getItem(termName)) || maxYears || 1,
  );
  const [termCommitted, setTermPaymentCommitted] = useState(Number(sessionStorage.getItem(termName)));
  const handleChangeTerm = useCallback(
    value => {
      setTerm(Number(value));
      sessionStorage.setItem(termName, value);
    },
    [termName],
  );

  const handleChangeTermCommitted = useCallback(
    value => {
      handleChangeTerm(value);
      setTermPaymentCommitted(value);
    },
    [handleChangeTerm],
  );

  useEffect(() => {
    const termFromSS = sessionStorage.getItem(termName);
    if (termFromSS && termFromSS !== 'undefined') {
      setTerm(Number(termFromSS));
      setTermPaymentCommitted(Number(termFromSS));
    } else if (maxYears) {
      setTerm(maxYears);
      setTermPaymentCommitted(maxYears);
    }
  }, [termName, maxYears]);

  useEffect(() => {
    if (sessionStorage.getItem(termName) !== 'undefined' && sessionStorage.getItem(termName) !== null) {
      setTerm(Number(sessionStorage.getItem(termName)));
    } else {
      setTerm(maxYears);
      setTermPaymentCommitted(maxYears);
    }
  }, [project, maxYears]);

  return { term, handleChangeTerm, termCommitted, handleChangeTermCommitted };
};
