import { createSlice } from '@reduxjs/toolkit';
import booleanSwitch from '../tools/boolean-switch';
import calcTheLargest from '../tools/calc-the-largest';
import calcTheLeast from '../tools/calc-the-least';
import getSelectedParams from '../tools/get-selected-params';
import resetSortingParameter from '../tools/reset-sorting-parameter';
import sortAbc from '../tools/sort-abc';
import sortDate from '../tools/sort-date';
import sortNumber from '../tools/sort-number';
import { BlockIds, DOMAIN_RELATIVE } from '../utils/constants';
import { convertDateIntoMonthAndYear, capitalizeFirstLetter } from '../utils/helpers/helpers.jsx';
import { comparedFlatsApi } from './ComparedFlatsSlice';
import { favoritesApi } from './FavoritesSlice';
import {
  apiRoutes,
  fetchAttributesListWorkplace,
  fetchCatalogWorkplace,
  fetchProjectsByRole,
  flatCheck,
} from './api/api';
import { unfilteredAdvantagesList } from '../utils/feature-flags.js';

const initialState = {
  projects: [],
  urls: {
    getResidential: {
      url: apiRoutes.urlCatalog,
      initial: false,
      status: 'idle',
      error: null,
    },
  },
  //Параметры фильтрации по чекбоксу
  filterParameters: {
    byCheckbox: {
      settlementDate: [],
      metroStations: [],
      advantages: [],
      bulk: [],
      finish: [
        { name: 'Финишная', value: 'finish', active: false, empty: null, disabled: false },
        { name: 'Без отделки', value: 'woWhitebox', active: false, empty: null, disabled: false },
        { name: 'White Box', value: 'whiteBox', active: false, empty: null, disabled: false },
        { name: 'White Box Max', value: 'whiteBoxMax', active: false, empty: null, disabled: false },
      ],
    },
    advantagesSvgIcons: [
      // 'oknoVVannoj',
      'highCeiling',
      // 'cornerGlazing',
      // 'fireplace',
      'livingKitchen',
      'loggia',
      // 'panoramicGlazing',
      'patio',
      // 'penthouse',
      // 'vidNaReku',
      'vidVoDvor',
      // 'sauna',
      // 'terrace',
      'dressingRoom',
      // 'balcony',
      'bath',
      // 'countOfBathrooms',
      'laundry',
      'masterBedroom',
      // 'notfirstfloor',
      'roomOnEast',
      'roomOnNorth',
      'roomOnSouth',
    ],
    selectedParams: {},
    //Параметры фильтрации по range-слайдерам
    //Крайние значения слайдеров
    slidersExtremeValues: {
      price: [0, 10],
      area: [0, 10],
      floor: [0, 10],
      ceilingHeight: [0, 10],
    },
    //Текущие значения слайдеров
    slidersValues: {
      price: [0, 10],
      area: [0, 10],
      floor: [0, 10],
      ceilingHeight: [0, 10],
    },
    //Значения инпутов
    inputsValues: {
      price: [0, 10],
      area: [0, 10],
      floor: [0, 10],
      ceilingHeight: [0, 10],
    },
    //Значения ползунков в фильтре по умолчанию
    initialValues: {
      area: [0, 10],
      price: [0, 10],
      floor: [0, 10],
      ceilingHeight: [0, 10],
    },
    inactiveSliders: {
      floor: false,
      price: false,
      area: false,
      ceilingHeight: false,
    },
    //Возможные параметры фильтрации
    params: ['floor', 'price', 'area', 'ceilingHeight'],
    //Параметры, которые были выбраны пользователем
    interactedParams: {
      floor: {
        min: false,
        max: false,
      },
      price: {
        min: false,
        max: false,
      },
      area: {
        min: false,
        max: false,
      },
      ceilingHeight: {
        min: false,
        max: false,
      },
    },
  },
  //Возможные параметры фильтрации
  params: ['floor', 'price', 'area', 'ceilingHeight'],
  amountOfSelectedParams: 0,
  amountSelectedRange: {
    floor: 0,
    price: 0,
    area: 0,
    ceilingHeight: 0,
  },
  //Текущий параметр сортировки
  sortParameters: {
    value: 'area',
    direction: 'asc_',
  },
  //Опции для сортировки выпадающим списком
  dropdownOptions: [
    { value: 'asc-price', label: 'По росту стоимости' },
    { value: 'dec-price', label: 'По убыванию стоимости' },
    { value: 'asc-area', label: 'По росту площади' },
    { value: 'dec-area', label: 'По убыванию площади' },
    { value: 'asc-sort', label: 'Сортировка' },
  ],
  //Текущая опция сортировки
  selectedOption: { value: 'asc-area', label: 'По росту площади' },
  potentialUniqueAttributesFlat: [''],
  //Места вызова функции
  //по клику на заголовок столбца таблицы
  inTableHead: 'inTableHead',
  //после изменения состояния формы
  inForm: 'inForm',
  //Квартиры, которые выводятся на страницу
  shownFlats: [],
  //Все квартиры, взятые с сервера
  allFlats: [],
  //Избранное
  favoriteFlats: [],
  //Сравнение
  comparedFlats: [],
  //Квартиры после фильтрации чекбоксами
  filteredByCheckboxFlats: [],
  //Список айдишников свободных квартир из тех, что хранились в избранном
  freeFlatsId: [],
  //Отфильтрован список квартир?
  isFiltered: null,
  //Особенности выбранной квартиры
  currentAdvantages: [],
  //Статус квартиры
  flatStatus: {
    free: 'free',
    reserve: 'reserve',
  },
  catalogPageDisplay: 'grid',
  isFiltersModalOpen: false,
  settlementDateFilter: null,
  currentFlatProject: '',
};

const catalogWorkplaceSlice = createSlice({
  name: 'flatsWorkplaceSlice',
  initialState,
  reducers: {
    //Получение актуальных характеристик квартир, по которым возможна фильтрация
    checkRelevantAttributesWorkplace(state) {
      const advantages = [];

      //Получение уникальных attributes;
      state.allFlats?.forEach(item => {
        Object.keys(item.attributes)?.forEach(elem => {
          if (!advantages?.find(advantage => advantage === elem)) {
            advantages.push(elem);
          }
        });
      });

      state.potentialUniqueAttributesFlat = advantages;

      //Если характеристика не заполнена ни у одной квартиры, то делаем её недоступной
      state.filterParameters.byCheckbox.advantages?.forEach(item => {
        const filtered = state.allFlats?.filter(elem => {
          if (elem.attributes[`${item.value}`] !== undefined) {
            return elem.attributes[`${item.value}`];
          }
          return null;
        });
        item.empty = filtered.length === 0;
      });
    },
    //Изменить активное состояние параметра в фильтре по чекбоксам
    toggleActiveSearchParamsWorkplace(state, action) {
      const possibleParameters = Object.keys(state.filterParameters.byCheckbox);
      for (const param of possibleParameters) {
        if (action.payload.id.includes(param)) {
          booleanSwitch(state.filterParameters.byCheckbox, param, 'active', action.payload.value);
          catalogWorkplaceSlice.caseReducers.filterFlats(state);
          return;
        }
      }

      booleanSwitch(state.filterParameters.byCheckbox, 'advantages', 'active', action.payload.value);
      catalogWorkplaceSlice.caseReducers.filterFlats(state);
    },

    filterFlats(state) {
      let amountOfSelectedParams = 0;
      //Фильтрация по range-слайдерам
      const interactedParams = state.filterParameters.interactedParams;

      const slidersValues = {
        price: [0, 0],
        area: [0, 0],
        floor: [0, 0],
        ceilingHeight: [0, 0],
      };

      Object.keys(interactedParams)?.forEach(key => {
        if (interactedParams[key].min) {
          slidersValues[key][0] = state.filterParameters.slidersValues[key][0];
        } else {
          slidersValues[key][0] = state.filterParameters.initialValues[key][0];
        }

        if (interactedParams[key].max) {
          slidersValues[key][1] = state.filterParameters.slidersValues[key][1];
        } else {
          slidersValues[key][1] = state.filterParameters.initialValues[key][1];
        }

        if (interactedParams[key].max || interactedParams[key].min) {
          amountOfSelectedParams++;
        }
      });

      const bySliders = flat => {
        return (
          flat.currentPrice >= slidersValues.price[0] &&
          flat.currentPrice <= slidersValues.price[1] &&
          flat.area >= slidersValues.area[0] &&
          flat.area <= slidersValues.area[1] &&
          flat.floor >= slidersValues.floor[0] &&
          flat.floor <= slidersValues.floor[1] &&
          flat.ceilingHeight >= slidersValues.ceilingHeight[0] &&
          flat.ceilingHeight <= slidersValues.ceilingHeight[1]
        );
      };

      let filtered = state.allFlats.filter(bySliders);

      //Получение актуальных параметров для фильтрации
      state.filterParameters.selectedParams = {
        finish: getSelectedParams(state.filterParameters.byCheckbox, 'finish').split(','),
        advantages: getSelectedParams(state.filterParameters.byCheckbox, 'advantages')?.split(','),
        metroStations: getSelectedParams(state.filterParameters.byCheckbox, 'metroStations'),
        settlementDate: getSelectedParams(state.filterParameters.byCheckbox, 'settlementDate'),
        bulk: getSelectedParams(state.filterParameters.byCheckbox, 'bulk'),
      };

      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox)?.forEach(key => {
        state.filterParameters.byCheckbox[key]?.forEach(item => {
          item.disabled = !item.active;
        });
      });

      const byAdvantages = flat => {
        for (let i = 0; i < state.filterParameters.selectedParams.advantages.length; i++) {
          if (!flat.attributes[state.filterParameters.selectedParams.advantages[i]]) {
            return false;
          }
        }
        return true;
      };

      const byFinish = flat => {
        let flatIsSuitable = false;

        for (let i = 0; i < state.filterParameters.selectedParams.finish.length; i++) {
          if (flat.attributes[state.filterParameters.selectedParams.finish[i]]) {
            flatIsSuitable = true;
          }
        }
        return flatIsSuitable;
      };

      const byMetroStations = flat => {
        return state.filterParameters.selectedParams.metroStations
          ? flat.block.metroStations.some(station =>
              state.filterParameters.selectedParams.metroStations.includes(station.name),
            )
          : true;
      };

      const bySettlementDate = flat => {
        return state.filterParameters.selectedParams.settlementDate
          ? state.filterParameters.selectedParams.settlementDate.includes(flat.bulk.keyDate)
          : true;
      };
      const byBulk = flat => {
        return state.filterParameters.selectedParams.bulk
          ? state.filterParameters.selectedParams.bulk.includes(flat.bulk.id)
          : true;
      };

      const byCheckbox = {
        advantages: byAdvantages,
        finish: byFinish,
        settlementDate: bySettlementDate,
        metroStations: byMetroStations,
        bulk: byBulk,
      };

      const findAdvantage = flat => {
        state.filterParameters.byCheckbox.advantages?.forEach(item => {
          if (flat.attributes[item.value]) {
            item.disabled = false;
          }
        });
      };

      const findFinish = flat => {
        state.filterParameters.byCheckbox.finish?.forEach(item => {
          if (flat.attributes[item.value]) {
            item.disabled = false;
          }
        });
      };

      const findSettlementDate = flat => {
        state.filterParameters.byCheckbox.settlementDate?.forEach(item => {
          if (flat.bulk.keyDate === item.value) {
            item.disabled = false;
          }
        });
      };

      //Метро
      const findMetroStations = flat => {
        state.filterParameters.byCheckbox.metroStations?.forEach(item => {
          flat.block.metroStations.forEach(station => {
            if (station.name === item.value) {
              item.disabled = false;
            }
          });
        });
      };
      const findBulk = flat => {
        state.filterParameters.byCheckbox.bulk.forEach(item => {
          if (flat.bulk.id === item.value) {
            item.disabled = false;
          }
        });
      };

      const findCheckbox = {
        advantages: findAdvantage,
        finish: findFinish,
        settlementDate: findSettlementDate,
        metroStations: findMetroStations,
        bulk: findBulk,
      };
      const params = ['advantages', 'finish', 'settlementDate', 'metroStations', 'bulk'];

      const chosenParams = [];
      const selectedParamsKeys = Object.keys(state.filterParameters.selectedParams);
      selectedParamsKeys?.forEach(key => {
        const param = state.filterParameters.selectedParams[key];
        if ((param && param?.length === 0) || (param?.length > 0 && param[0])) {
          chosenParams.push(key);
          if (key !== 'advantages') {
            amountOfSelectedParams++;
          } else {
            amountOfSelectedParams += param?.length;
          }
        }
      });

      state.amountOfSelectedParams = amountOfSelectedParams;

      const amountOfChosenParams = chosenParams.length;

      if (amountOfChosenParams === 0) {
        state.shownFlats = filtered;
        catalogWorkplaceSlice.caseReducers.setAvailableCheckboxes(state);
      }

      if (amountOfChosenParams === 1) {
        if (chosenParams[0] !== 'advantages') {
          filtered.forEach(flat => {
            findCheckbox[chosenParams[0]](flat);
          });
        }

        const restOfParams = params.filter(param => param !== chosenParams[0] && param !== 'advantages');

        state.shownFlats = filtered?.filter(byCheckbox[chosenParams[0]]);
        state.shownFlats?.forEach(flat => {
          findCheckbox.advantages(flat);
          restOfParams.forEach(param => {
            findCheckbox[param](flat);
          });
        });
      }

      if (amountOfChosenParams >= 2) {
        for (const param of chosenParams) {
          if (param !== 'advantages') {
            const otherParams = chosenParams.filter(p => p !== param);
            let filteredByOtherParams = filtered;
            otherParams.forEach(otherParam => {
              filteredByOtherParams = filteredByOtherParams.filter(byCheckbox[otherParam]);
            });
            filteredByOtherParams.forEach(flat => {
              findCheckbox[param](flat);
            });
          }
        }

        chosenParams.forEach(chosenParam => {
          filtered = filtered.filter(byCheckbox[chosenParam]);
        });

        state.shownFlats = filtered;

        const restOfParams = params.filter(param => !chosenParams.includes(param) && param !== 'advantages');

        state.shownFlats.forEach(flat => {
          findCheckbox.advantages(flat);
          restOfParams.forEach(param => {
            findCheckbox[param](flat);
          });
        });
      }

      catalogWorkplaceSlice.caseReducers.setExtremeSlidersValues(state);
    },

    //Сбросить состояние списка квартир до первоначального
    resetFiltersWorkplace(state) {
      state.amountOfSelectedParams = 0;
      const checkboxParams = Object.keys(state.filterParameters.byCheckbox);

      checkboxParams?.forEach(item => {
        state.filterParameters.byCheckbox[item] = resetSortingParameter(state.filterParameters.byCheckbox[item]);
      });

      const interactedParams = Object.keys(state.filterParameters.interactedParams);
      interactedParams?.forEach(item => {
        state.filterParameters.interactedParams[item].max = false;
        state.filterParameters.interactedParams[item].min = false;
      });

      state.shownFlats = state.allFlats;
      catalogWorkplaceSlice.caseReducers.setExtremeSlidersValues(state);
      catalogWorkplaceSlice.caseReducers.setAvailableCheckboxes(state);
    },

    //Отсортировать квартиры по клику на заголовок таблицы
    sortFlatsWorkplace(state, action) {
      const value = action.payload.value;
      // Исключаем фильтр по характеристикам/отделке/схеме
      if (value !== 'advantages' && value !== 'finish' && value !== 'scheme') {
        let direction = state.sortParameters.direction;

        if (action.payload.placeCall === state.inTableHead) {
          //Если вызов произошел по клику на заголовок таблицы
          //переключить направления сортировки
          if (direction === 'asc_') {
            direction = 'desc_';
          } else {
            direction = 'asc_';
          }
        }

        state.sortParameters = {
          value: action.payload.value,
          direction,
        };

        const keyTyped = action.payload.array;
        const stateArray = state[keyTyped];

        if (Array.isArray(stateArray) && stateArray.length > 0) {
          if (action.payload.value === 'bulk_number') {
            sortNumber(stateArray, 'bulk.number', state.sortParameters.direction);
          } else if (action.payload.value === 'date') {
            sortDate(stateArray, 'bulk.keyDate', state.sortParameters.direction);
          } else if (action.payload.value === 'number') {
            sortAbc(stateArray, 'number', state.sortParameters.direction);
          } else {
            sortNumber(
              stateArray,
              state.sortParameters.value.replace('price', 'currentPrice'),
              state.sortParameters.direction,
            );
          }
        }
      }
    },
    //Сменить текущую опцию сортировки
    changeSelectedDropdownOptionWorkplace: (state, action) => {
      if (action.payload) {
        // @ts-ignore
        state.selectedOption = action.payload;
      }
    },
    //Отсортировать квартиры по выпадающему списку
    sortFlatsListByDropdownWorkplace: (state, action) => {
      const flatsArray = action.payload.array;

      if (typeof action.payload.value.value === 'string') {
        if (action.payload.value.value.includes('asc-') || action.payload.value.value.includes('dec-')) {
          const direction = `${action.payload.value.value.slice(0, 3)}_`;
          const params = action.payload.value.value.replace('asc-', '').replace('dec-', '');
          sortNumber(state[flatsArray], params.replace('price', 'currentPrice'), direction);
        } else {
          const withWhiteBox = state[flatsArray].filter(item => item.attributes.whiteBox === true);
          const withoutWhiteBox = state[flatsArray].filter(item => item.attributes.whiteBox === false);

          if (action.payload.value.value.includes('with-')) {
            state[flatsArray] = withWhiteBox.concat(withoutWhiteBox);
          } else {
            state[flatsArray] = withoutWhiteBox.concat(withWhiteBox);
          }
        }
      }
    },

    changeCatalogPageDisplayWorkplace(state, action) {
      state.catalogPageDisplay = action.payload;
    },
    changeCurrentFlatProjectWorkplace(state, action) {
      state.currentFlatProject = action.payload;
    },

    setAvailableCheckboxes(state) {
      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox)?.forEach(key => {
        state.filterParameters.byCheckbox[key]?.forEach(item => {
          item.disabled = !item.active;
        });
      });
      //Если характеристика заполнена хотя бы у одной квартиры, то делаем её доступной
      for (let i = 0; i < state.shownFlats.length; i++) {
        const flat = state.shownFlats[i];

        Object.keys(flat.attributes)?.forEach(key => {
          if (flat.attributes[key]) {
            state.filterParameters.byCheckbox.advantages?.forEach(item => {
              if (item.value === key) {
                item.disabled = false;
              }
            });
            state.filterParameters.byCheckbox.finish?.forEach(item => {
              if (item.value === key) {
                item.disabled = false;
              }
            });
          }
        });

        //Метро
        state.filterParameters.byCheckbox.metroStations?.forEach(item => {
          flat.block.metroStations.forEach(station => {
            if (station.name === item.value) {
              item.disabled = false;
            }
          });
        });

        state.filterParameters.byCheckbox.settlementDate.forEach(item => {
          if (flat.bulk.keyDate === item.value) {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.bulk.forEach(item => {
          if (flat.bulk.id === item.value) {
            item.disabled = false;
          }
        });
      }
    },
    setExtremeSlidersValues(state) {
      if (state.allFlats.length === 0) {
        state.filterParameters.params.forEach(param => {
          const maxParam = state.filterParameters.initialValues[param][1];
          const minParam = state.filterParameters.initialValues[param][0];

          state.filterParameters.slidersExtremeValues[param] = [minParam, maxParam];
          state.filterParameters.slidersValues[param] = [minParam, maxParam];
          state.filterParameters.inputsValues[param] = [minParam, maxParam];
        });
      }

      // Расчет максимумов и минимумов для слайдеров с обновленными данными
      state.filterParameters.params?.forEach(param => {
        const interactedParams = state.filterParameters.interactedParams[param];

        const largestValue = calcTheLargest(state.shownFlats, param);
        const leastValue = calcTheLeast(state.shownFlats, param, largestValue);
        let maxParam;
        if (param !== 'price' && param !== 'ceilingHeight') {
          maxParam = Math.ceil(largestValue);
        } else if (param === 'ceilingHeight') {
          maxParam = largestValue;
        } else {
          maxParam = Math.ceil(largestValue / 1000000) * 1000000;
        }

        let minParam;
        if (param !== 'price' && param !== 'ceilingHeight') {
          minParam = Math.floor(leastValue);
        } else if (param === 'ceilingHeight') {
          minParam = leastValue;
        } else {
          minParam = Math.floor(leastValue / 1000000) * 1000000;
        }

        if (
          (maxParam - minParam === 1 ||
            (param === 'price' && maxParam - minParam === 1000000) ||
            minParam === maxParam) &&
          minParam !== 0
        ) {
          const [initialMinParam, initialMaxParam] = state.filterParameters.initialValues[param];

          if (!interactedParams?.min && !interactedParams?.max) {
            state.filterParameters.inactiveSliders[param] = true;
            state.filterParameters.slidersValues[param] = [initialMinParam, initialMaxParam];
            state.filterParameters.inputsValues[param] = [minParam, maxParam];
            state.filterParameters.slidersExtremeValues[param] = [initialMinParam, initialMaxParam];
          }
        } else if (minParam !== 0 && minParam !== maxParam) {
          state.filterParameters.inactiveSliders[param] = false;

          if (!interactedParams.min) {
            state.filterParameters.slidersValues[param][0] = minParam;
            state.filterParameters.inputsValues[param][0] = minParam;
            state.filterParameters.slidersExtremeValues[param][0] = minParam;
          }

          if (!interactedParams.max) {
            state.filterParameters.slidersValues[param][1] = maxParam;
            state.filterParameters.inputsValues[param][1] = maxParam;
            state.filterParameters.slidersExtremeValues[param][1] = maxParam;
          }
        } else if (minParam === 0 && minParam === maxParam && !interactedParams?.min && !interactedParams?.max) {
          const [lastExtremeValueMin, lastExtremeValueMax] = state.filterParameters.slidersExtremeValues[param];

          state.filterParameters.inactiveSliders[param] = true;
          state.filterParameters.slidersValues[param] = [lastExtremeValueMin, lastExtremeValueMax];
          state.filterParameters.inputsValues[param] = [lastExtremeValueMin, lastExtremeValueMax];
        }
      });
    },

    updateSliderValuesWorkplace(state, action) {
      //Проверка на изменение значений - если значения отличаются от максимальных/минимальных, то
      //пользователь взаимодействовал с ползунками, записываем это в interactedParams и
      //записываем новые значения в slidersValues
      //иначе - пользователь вернул значения в исходное положение, записываем false в interactedParams
      //и записываем значения из initialValues в slidersValues

      if (Number(action.payload.values[0]) !== state.filterParameters.slidersExtremeValues[action.payload.slider][0]) {
        state.filterParameters.interactedParams[action.payload.slider].min = true;
        state.filterParameters.slidersValues[action.payload.slider][0] = Number(action.payload.values[0]);
      } else {
        state.filterParameters.interactedParams[action.payload.slider].min = false;
        state.filterParameters.slidersValues[action.payload.slider][0] =
          state.filterParameters.initialValues[action.payload.slider][0];
      }

      if (Number(action.payload.values[1]) !== state.filterParameters.slidersExtremeValues[action.payload.slider][1]) {
        state.filterParameters.interactedParams[action.payload.slider].max = true;
        state.filterParameters.slidersValues[action.payload.slider][1] = Number(action.payload.values[1]);
      } else {
        state.filterParameters.interactedParams[action.payload.slider].max = false;
        state.filterParameters.slidersValues[action.payload.slider][1] =
          state.filterParameters.initialValues[action.payload.slider][1];
      }

      catalogWorkplaceSlice.caseReducers.filterFlats(state);
    },
    //Обновить значения для отображения в изменяемом range-слайдере
    updateInputValuesWorkplace(state, action) {
      //Записываем новые значения в активный слайдер
      state.filterParameters.inputsValues[action.payload.slider][0] = Number(action.payload.values[0]);
      state.filterParameters.inputsValues[action.payload.slider][1] = Number(action.payload.values[1]);
    },

    //открытие модального окна
    openModalWorkplace: state => {
      state.isFiltersModalOpen = true;
    },
    // закрытие модального окна
    closeModalWorkplace: state => {
      state.isFiltersModalOpen = false;
    },

    updateMultipleFilterParamsWorkplace(state, action) {
      const { area, price, advantages, ceilingHeight } = action.payload;
      const sliders = [
        area ? { slider: 'area', newValues: area } : null,
        price ? { slider: 'price', newValues: price } : null,
        ceilingHeight ? { slider: 'ceilingHeight', newValues: ceilingHeight } : null,
      ].filter(slider => slider !== null);

      const checkboxes = [
        // finish ? { checkbox: 'finish', newValues: finish } : null,
        advantages ? { checkbox: 'advantages', newValues: advantages } : null,
      ].filter(checkbox => checkbox !== null);

      catalogWorkplaceSlice.caseReducers.updateMultipleSliders(state, { payload: sliders });
      catalogWorkplaceSlice.caseReducers.updateMultipleCheckboxes(state, { payload: checkboxes });
    },
    updateMultipleCheckboxes(state, action) {
      const checkboxes = action.payload;

      for (const { checkbox, newValues } of checkboxes) {
        for (const value of newValues) {
          catalogWorkplaceSlice.caseReducers.toggleActiveSearchParamsWorkplace(state, {
            payload: { id: checkbox, value },
          });
        }
      }
    },
    updateMultipleSliders(state, action) {
      const sliders = action.payload;

      sliders.forEach(({ slider, newValues }) => {
        let x = newValues[0];
        let y = newValues[1];

        x = Math.ceil(x);
        y = Math.floor(y);

        if (slider === 'price') {
          x *= 1000000;
          y *= 1000000;
        }

        if (x < Number(state.filterParameters.slidersExtremeValues[slider][0])) {
          x = state.filterParameters.slidersExtremeValues[slider][0];
        }
        if (x > Number(state.filterParameters.slidersExtremeValues[slider][1])) {
          x = state.filterParameters.slidersExtremeValues[slider][1];
        }

        if (y > Number(state.filterParameters.slidersExtremeValues[slider][1])) {
          y = state.filterParameters.slidersExtremeValues[slider][1];
        }
        if (y < Number(state.filterParameters.slidersExtremeValues[slider][0])) {
          y = state.filterParameters.slidersExtremeValues[slider][0];
        }

        const values = [x, y];

        catalogWorkplaceSlice.caseReducers.updateInputValuesWorkplace(state, { payload: { slider, values } });
        catalogWorkplaceSlice.caseReducers.updateSliderValuesWorkplace(state, { payload: { slider, values } });
      });
    },

    filterStateFromSearchParamsWorkplace(state) {
      const filtersByCheckbox = state.filterParameters.byCheckbox;
      const searchParams = new URLSearchParams(window.location.search);
      if (!searchParams.size) return;

      for (const filterParam in filtersByCheckbox) {
        if (Object.hasOwn(filtersByCheckbox, filterParam)) {
          const filterParamValues = searchParams.get(filterParam)?.split(',');
          if (!filterParamValues) continue;
          for (const value of filterParamValues) {
            const filterParamObj = filtersByCheckbox[filterParam].find(param => String(param.value) === value);

            if (filterParamObj && !filterParamObj.active) {
              catalogWorkplaceSlice.caseReducers.toggleActiveSearchParamsWorkplace(state, {
                payload: {
                  id: filterParam,
                  value: filterParamObj.value,
                },
              });
            }
          }
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjectsByRole.fulfilled, (state, action) => {
        state.projects = action.payload.projectsByRole.map(({ name, project_id }, index) => {
          const editedName = name.toLowerCase() === 'først' ? 'forst' : name.toLowerCase();
          const matchingProject = action.payload.bulkProjects.find(project => project.id === project_id);
          return {
            id: project_id,
            value: `${index}`,
            title: name,
            name: editedName,
            url: `${DOMAIN_RELATIVE}/api/v2/commercial?blockId=${project_id}`,
            active: project_id === BlockIds.FORST_BLOCK_ID,
            blockId: project_id,
            objectType: matchingProject.objectType,
          };
        });
      })
      .addCase(fetchCatalogWorkplace.pending, state => {
        state.urls.getResidential.status = 'pending';
        state.urls.getResidential.error = null;
        console.log('Идет запрос на сервер...');
      })
      .addCase(fetchCatalogWorkplace.fulfilled, (state, action) => {
        state.urls.getResidential.status = 'idle';
        let freeFlats = action.payload.flats.filter(item => item.status === 'free');

        state.filterParameters.byCheckbox.settlementDate = [];
        state.filterParameters.byCheckbox.metroStations = [];
        state.filterParameters.byCheckbox.bulk = [];

        for (const freeFlat of freeFlats) {
          const settlementDate = freeFlat.bulk.keyDate;
          if (
            state.filterParameters.byCheckbox.settlementDate.filter(item => item.value === settlementDate).length === 0
          ) {
            if (!settlementDate) return;

            const settlementDateItem = {
              name: `${convertDateIntoMonthAndYear(new Date(settlementDate))}`,
              value: settlementDate,
              disabled: false,
              active: false,
            };
            state.filterParameters.byCheckbox.settlementDate.push(settlementDateItem);
          }

          const bulkId = freeFlat.bulk.id;
          if (state.filterParameters.byCheckbox.bulk.filter(item => item.value === bulkId).length === 0) {
            const bulkName = freeFlat.bulk.name;

            const bulkItem = {
              name: bulkName,
              value: bulkId,
              disabled: false,
              active: false,
            };
            state.filterParameters.byCheckbox.bulk.push(bulkItem);
          }
        }

        const metroStationsArray = [];
        for (const item of freeFlats) {
          const metroStations = item.block.metroStations;
          // biome-ignore lint/complexity/noForEach: <explanation>
          metroStations.forEach(station => {
            const existingStation = metroStationsArray.find(s => s.name === station.name);
            if (!existingStation) {
              metroStationsArray.push(station);
            }
          });
        }

        state.filterParameters.byCheckbox.metroStations = Array.from(metroStationsArray)
          .sort()
          .map(metro => {
            return {
              name: capitalizeFirstLetter(metro.name),
              value: metro.name,
              active: false,
              disabled: false,
            };
          });

        if (!unfilteredAdvantagesList) {
          if (freeFlats.length > 1) {
            freeFlats = freeFlats.map(el => {
              if (el.attributes.whiteBox && el.attributes.whiteBoxMax) {
                el.attributes.whiteBox = false;
              }
              return el;
            });
          }
        }
        if (unfilteredAdvantagesList) {
          state.filterParameters.byCheckbox.advantages = action.payload.attributeList?.map(attr => {
            return {
              name: capitalizeFirstLetter(attr.description || attr.name),
              value: attr.name,
              active: false,
              empty: null,
              disabled: false,
              category: attr.category,
            };
          });
        }

        state.allFlats = freeFlats;
        state.shownFlats = freeFlats;
        console.log('Квартиры загрузились');

        state.urls.getResidential.initial = true;
        state.urls.getResidential.status = 'fulfilled';

        const maxFloor = calcTheLargest(state.shownFlats, 'floor');
        const minFloor = calcTheLeast(state.shownFlats, 'floor', maxFloor);

        const maxCeilingHeight = calcTheLargest(state.shownFlats, 'ceilingHeight');
        const minCeilingHeight = calcTheLeast(state.shownFlats, 'ceilingHeight', maxCeilingHeight);

        state.filterParameters.initialValues = {
          area: [Math.floor(action.payload.area.min), Math.ceil(action.payload.area.max)],
          price: [
            Math.floor(action.payload.price.min / 1000000) * 1000000,
            Math.ceil(action.payload.price.max / 1000000) * 1000000,
          ],
          floor: [minFloor, maxFloor],
          ceilingHeight: [minCeilingHeight, maxCeilingHeight],
        };

        catalogWorkplaceSlice.caseReducers.checkRelevantAttributesWorkplace(state);
        catalogWorkplaceSlice.caseReducers.setExtremeSlidersValues(state);
        catalogWorkplaceSlice.caseReducers.setAvailableCheckboxes(state);

        const payload = {
          value: state.sortParameters.value,
          placeCall: state.inForm,
          array: 'shownFlats',
        };
        catalogWorkplaceSlice.caseReducers.sortFlatsWorkplace(state, { payload, type: '' });
        catalogWorkplaceSlice.caseReducers.filterStateFromSearchParamsWorkplace(state);
      })
      .addCase(fetchCatalogWorkplace.rejected, (state, action) => {
        state.urls.getResidential.status = 'idle';
        console.log('Ошибка при запросе на сервер', action.payload);
        state.urls.getResidential.error = 'error';
        state.urls.getResidential.status = 'rejected';
      })
      .addCase(flatCheck.pending, () => {
        console.log('Идет проверка сохраненных квартир...');
      })
      .addCase(flatCheck.fulfilled, (state, action) => {
        //Собираем список свободных квартир
        state.freeFlatsId = Object.keys(action.payload).filter(item => action.payload[`${item}`]);
      })
      .addCase(flatCheck.rejected, (state, action) => {
        console.log('Ошибка при запросе на сервер', action.payload);
      })

      //Заполенине атрибутов для избранного
      .addCase(fetchAttributesListWorkplace.pending, () => {
        console.log('Идет проверка сохраненных квартир...');
      })
      .addCase(fetchAttributesListWorkplace.fulfilled, (state, action) => {
        state.filterParameters.byCheckbox.advantages = action.payload.data?.map(attr => {
          return {
            name: capitalizeFirstLetter(attr.description || attr.name),
            value: attr.name,
            active: false,
            empty: null,
            disabled: false,
            category: attr.category,
          };
        });
      })
      .addCase(fetchAttributesListWorkplace.rejected, (state, action) => {
        console.log('Ошибка при запросе на сервер', action.payload);
      })

      //Удаляем из избранного
      .addMatcher(
        favoritesApi.endpoints.deleteFavoriteFlat.matchFulfilled,
        (
          state,
          {
            meta: {
              arg: { flatId },
            },
          },
        ) => {
          state.favoriteFlats = state.favoriteFlats.filter(flat => flat.id !== flatId);
        },
      )
      //Получаем квартиры в хуке из favoriteSlice и передаем его в favoriteFlats
      .addMatcher(favoritesApi.endpoints.getFavoritesFlats.matchFulfilled, (state, { payload }) => {
        state.favoriteFlats = payload.map(flat => flat.data);
      })
      .addMatcher(comparedFlatsApi.endpoints.getComparedFlats.matchFulfilled, (state, { payload }) => {
        state.comparedFlats = payload;
      });
  },
});

export default catalogWorkplaceSlice.reducer;

export const {
  toggleActiveSearchParamsWorkplace,
  resetFiltersWorkplace,
  sortFlatsWorkplace,
  changeSelectedDropdownOptionWorkplace,
  sortFlatsListByDropdownWorkplace,
  changeCatalogPageDisplayWorkplace,
  updateInputValuesWorkplace,
  updateSliderValuesWorkplace,
  openModalWorkplace,
  closeModalWorkplace,
  updateMultipleFilterParamsWorkplace,
  changeCurrentFlatProjectWorkplace,
  filterStateFromSearchParamsWorkplace,
} = catalogWorkplaceSlice.actions;
