export const openUploadingCsv = import.meta.env.VITE_OPEN_UPLOADING_CSV_FOR_COMPARISON_MONITORING_16839 === 'true';
export const unfilteredAdvantagesList = import.meta.env.VITE_UNFILTERED_ADVATANGES_LIST_16822 === 'true';
export const showErrorModalOnNetworkErrors = import.meta.env.VITE_ERROR_HANDLING_MODAL_14821 === 'true';
export const activComponentYandexCart = import.meta.env.VITE_YANDEX_MAP_17201 === 'true';
export const chessCatalogDisplay = import.meta.env.VITE_CHESS_CATALOG_DISPLAY_17137 === 'true';
export const commercialFormEnabled = import.meta.env.VITE_COMMERCIAL_FORM_16221 === 'true';
export const displayProjectsByRole = import.meta.env.VITE_DISPLAY_PROJECTS_BY_ROLE_18146 === 'true';
export const loggingBySW = import.meta.env.VITE_LOGGING_BY_SW_18383 === 'true';
export const activCatalogWorkplace = import.meta.env.VITE_CATALOG_WORKPLACE_18909 === 'true';
export const pwaFormSubmission = import.meta.env.VITE_PWA_FORM_SUBMISSION_19159 === 'true';
export const soulUniquePresentation = import.meta.env.VITE_SOUL_UNIQUE_PRESENTATION_19319 === 'true';
export const favoritesCommercial = import.meta.env.VITE_FAVORITES_COMMERCIAL_18132 === 'true';
export const favoritesNew = import.meta.env.VITE_FAVORITES_NEW_19054 === 'true';
export const accreditationList = import.meta.env.VITE_ACCREDITATION_19993 === 'true';
export const workingSchedule = import.meta.env.VITE_WORK_SCHEDULE === 'true';
export const catalogRetail = import.meta.env.VITE_CATALOG_RETAIL_21560 === 'true';
export const partnerPrograms = import.meta.env.VITE_PARTNER_PROGRAMS_21505 === 'true';
export const newSurveyItems = import.meta.env.VITE_NEW_SURVEY_ITEMS_21187 === 'true';
export const calculatorFreeTab = import.meta.env.VITE_CALCULATOR_FREE_TAB_21868 === 'true';
export const bookingRedirectToPartner = import.meta.env.VITE_BOOKING_REDIRET_TO_PARTNER_23195 === 'true';
