import { useEffect } from 'react';
import ROUTES from '../entities/api/routes';
import { WORD_ENDINGS } from './constants';
import advantageNameFormat from '../tools/advantage-name-format';

export const handleBodyLock = () => {
  const exceptionPages = ['/monitor-meetings'];

  if (exceptionPages.includes(window.location.pathname)) {
    return;
  }

  setTimeout(() => {
    if (!('bodyScrollFix' in document.body.dataset)) {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      document.body.dataset.bodyScrollFix = scrollPosition;
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.left = '0';
      document.body.style.width = '100%';
    }
  }, 15);
};

export const handleBodyUnlock = () => {
  if ('bodyScrollFix' in document.body.dataset) {
    const scrollPosition = document.body.dataset.bodyScrollFix;
    delete document.body.dataset.bodyScrollFix;
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    document.body.style.width = '';
    window.scroll(0, scrollPosition);
  }
};

export const handleReload = () => {
  window.location.reload();
};

export const toFormat = value => {
  const res = value
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .concat(' ₽');
  return res;
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export const clearUserData = (changeLocation = true) => {
  localStorage.removeItem('user');
  localStorage.removeItem('user_roles');
  if (changeLocation) {
    window.location.href = ROUTES.root;
  } else {
    window.location.reload();
  }
};

export const isTabBenefit = activeTab => activeTab === 3 || activeTab === 4 || activeTab === 5;

export const inclineWord = (num, type) => {
  const n = num ? num.toString() : '1';
  const last = n.slice(-1);
  const twoLast = n.slice(-2);
  if (twoLast === '11' || twoLast === '12' || twoLast === '13' || twoLast === '14') {
    return `${n} ${WORD_ENDINGS[type].firstState}`;
  }
  if (last === '1') {
    return `${n} ${WORD_ENDINGS[type].secondState}`;
  }
  if (last === '2' || last === '3' || last === '4') {
    return `${n} ${WORD_ENDINGS[type].thirdState}`;
  }
  return `${n} ${WORD_ENDINGS[type].fourthState}`;
};

export function formatPrice(num) {
  const formatter = new Intl.NumberFormat('ru-RU');
  return formatter.format(num);
}

export function pxSize(size1, size2) {
  return `${Math.max(size1, size1 + (size2 - size1) * ((window.innerWidth - 1600) / (2560 - 1600)))}px`;
}

export const clearEqualizerStorage = () => {
  sessionStorage.removeItem('loanTermCompare');
  sessionStorage.removeItem('activeSwitchEq');
  sessionStorage.removeItem('activeProjectNewLotEqualizer');
  sessionStorage.removeItem('activeProjectNewPriceEqualizer');
  sessionStorage.removeItem('activeTabEqualizer');
  sessionStorage.removeItem('currBulkLotEqualizer');
  sessionStorage.removeItem('currFlatLotEqualizer');
  sessionStorage.removeItem('currBenefitLotEqualizer');
  sessionStorage.removeItem('currHouseLotEqualizer');
  sessionStorage.removeItem('currSectionLotEqualizer');
  sessionStorage.removeItem('currBenefitGroupPriceEqualizer');
  sessionStorage.removeItem('currFlatPricePriceEqualizer');
  sessionStorage.removeItem('currBenefitPriceEqualizer');
  sessionStorage.removeItem('currBenefitFreeEqualizer');
  sessionStorage.removeItem('currFlatPriceFreeEqualizer');
  sessionStorage.removeItem('currFirstPaymentFreeEqualizer');
  sessionStorage.removeItem('currTermFreeEqualizer');
};

export const clearLocalStorageFormData = () => {
  localStorage.removeItem('formData');
  localStorage.removeItem('form_data');
  localStorage.removeItem('formikValues');
  localStorage.removeItem('isFirstPageFilled');
  localStorage.removeItem('isSecondPageFilled');
  localStorage.removeItem('isPresentationFinished');
  localStorage.removeItem('finalResult');
  localStorage.removeItem('isRedirectedFromFinal');
  localStorage.removeItem('isNotFirstMeeting');
  localStorage.removeItem('currentPath');
  localStorage.removeItem('savedListFavorites');
  localStorage.removeItem('lastEventId');
};

export const clearCalculatorStorage = () => {
  sessionStorage.removeItem('currBenefitPrice');
  sessionStorage.removeItem('currBenefitLot');
  sessionStorage.removeItem('currBenefitGroup');
  sessionStorage.removeItem('activeProjectNewLot');
  sessionStorage.removeItem('activeProjectNewPrice');
  sessionStorage.removeItem('currBulkLot');
  sessionStorage.removeItem('currHouseLot');
  sessionStorage.removeItem('currFlatLot');
  sessionStorage.removeItem('currBenefitGroupPrice');
  sessionStorage.removeItem('currFlatPricePrice');
  sessionStorage.removeItem('currSectionLot');
  sessionStorage.removeItem('currTermLot');
  sessionStorage.removeItem('currTermPrice');
  sessionStorage.removeItem('currBenefitFree');
  sessionStorage.removeItem('currFirstPaymentFree');
  sessionStorage.removeItem('currFlatPriceFree');
  sessionStorage.removeItem('currTermFree');
  sessionStorage.removeItem('currSection');
  sessionStorage.removeItem('currHouse');
  sessionStorage.removeItem('currBulk');
  sessionStorage.removeItem('currFlat');
  sessionStorage.removeItem('currBenefit');
  sessionStorage.removeItem('term');
};

export const clearAccreditationStorage = () => {
  sessionStorage.removeItem('accredDisplay');
  sessionStorage.removeItem('accredCorrectionItems');
  sessionStorage.removeItem('activRevision');
};

const hasValue = (arr, value) => arr?.some(item => item.value === value);

export const removeArrDuplicates = (arr, value) => {
  return arr.filter((item, index, self) => {
    return item.value !== value || index === self.findIndex(i => i.value === value);
  });
};

export const filterByFinishType = arr => {
  const hasWhiteBoxMax = hasValue(arr, 'whiteBoxMax');
  const hasWhiteBox = hasValue(arr, 'whiteBox');

  if (hasWhiteBoxMax) {
    // при наличии whiteBoxMax
    // удаляем все whiteBox
    // удаляем дубликаты whiteBoxMax
    return removeArrDuplicates(
      arr.filter(item => item.value !== 'whiteBox'),
      'whiteBoxMax',
    );
  }

  if (hasWhiteBox) {
    // при отсутствии whiteBoxMax и наличии whiteBox
    // удаляем дубликаты whiteBox
    // возвращаем whiteBox
    return removeArrDuplicates(arr, 'whiteBox');
  }

  return arr;
};

export const getAdvantageName = (item, flat) => {
  const formattedName = advantageNameFormat(flat, item);
  return formattedName === 'White Box' ? 'White Box Max' : formattedName;
};

export const logoutClearData = () => {
  clearUserData();
  clearLocalStorageFormData();
};
