export const mockMeetingsSchedule = [
  {
    id: 129,
    firstName: 'Айлана',
    lastName: 'Куулар',
    patronymic: null,
    email: 'kuularak@forma.ru',
    password: '$2b$05$t/cjw2yqN6R8NX6NkMDbjOlS45tP.6AYjNuYN9sf7SIbR/Dj6Ay86',
    isActive: true,
    agency_id: '13',
    bitrix_id: null,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 130,
    firstName: 'Елена',
    lastName: 'Охотникова',
    patronymic: null,
    email: 'okhotnikovaev@forma.ru',
    password: '$2b$05$M7WO1Sk/akq8vEMDMklct.sZyPmogEolZtVFKBOdQwebFsDHTw60O',
    isActive: true,
    agency_id: '13',
    bitrix_id: null,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      {
        time: '08:30',
        meeting: {
          id: 100324582,
          date: '2024-10-07T08:30:00.000Z',
          name: 'Встреча Елены',
          office: 'S4',
          canceled: true,
          activeMeeting: false,
          duration: 30,
          endTime: '2024-10-07T09:00:00.000Z',
        },
        lunch: false,
      },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 162,
    firstName: 'Виктор',
    lastName: 'Гусельников',
    patronymic: null,
    email: 'v.guselnikov@keep-calm.ru',
    password: '$2b$05$Es35n5XUwadqFEynMmZL/Oq2eWq7XxK5HH8rd3aDdBgOR3gnSpnYa',
    isActive: true,
    agency_id: '11',
    bitrix_id: null,
    phone: '79186777440',
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 163,
    firstName: 'Вадим',
    lastName: 'Баздырев',
    patronymic: null,
    email: 'bazdyrevvn@forma.ru',
    password: '$2b$05$35OLOeQ7pKh6DWsl1S0kFurtx64pQpNRADu/QjHMvhkQUM/jQfvkq',
    isActive: true,
    agency_id: '2',
    bitrix_id: 447,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      {
        time: '15:30',
        meeting: {
          id: 1003282,
          date: '2024-10-07T15:30:00.000Z',
          name: 'Встреча Вадима',
          office: 'S4',
          canceled: true,
          activeMeeting: false,
          duration: 30,
          endTime: '2024-10-07T18:00:00.000Z',
        },
        lunch: false,
      },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 168,
    firstName: 'Никита',
    lastName: 'Темников',
    patronymic: null,
    email: 'temnikovna@forma.ru',
    password: '$2b$05$S39k8sEflGAkSCHGaSWbour3m8qRhAnA.QTpRQxsjzsvVDk6SEA5m',
    isActive: true,
    agency_id: '2',
    bitrix_id: 477,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: true,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      {
        time: '18:30',
        meeting: {
          id: 100328221,
          date: '2024-10-07T18:30:00.000Z',
          name: 'Встреча Никита',
          office: 'S4',
          canceled: true,
          activeMeeting: false,
          duration: 30,
          endTime: '2024-10-07T19:00:00.000Z',
        },
        lunch: false,
      },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 169,
    firstName: 'RR',
    lastName: 'TEST',
    patronymic: null,
    email: 'test2@keep-calm.ru',
    password: '$2b$05$KwRilwsreKHuFbjriucZfeqEyrf2j7WzkjJJHzWltcmYOFkvlSCZa',
    isActive: true,
    agency_id: '11',
    bitrix_id: null,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 176,
    firstName: 'Николай',
    lastName: 'Титов',
    patronymic: null,
    email: 'titovnn@forma.ru',
    password: '$2b$05$ME.mKWWOkqafF7jFfPGSGestN40LNzim3HeJZR9tv8fBfDCTMb9V2',
    isActive: true,
    agency_id: '5',
    bitrix_id: 499,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 177,
    firstName: 'Андрей',
    lastName: 'Круглик',
    patronymic: null,
    email: 'a.kruglik@keep-calm.ru',
    password: '$2b$05$TStOwgaQZhUPXsrY7eRHnOVBrHgCXJi5nPaFhkYTrAJCRprtgaTES',
    isActive: true,
    agency_id: '11',
    bitrix_id: null,
    phone: null,
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      { time: '08:00', meeting: false, lunch: false },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      {
        time: '10:00',
        meeting: {
          id: 1003182,
          date: '2024-10-07T10:00:00.000Z',
          name: 'Встреча Андрея',
          office: 'S4',
          canceled: true,
          activeMeeting: false,
          duration: 30,
          endTime: '2024-10-07T14:00:00.000Z',
        },
        lunch: false,
      },
      { time: '14:00', meeting: false, lunch: false },
      { time: '14:30', meeting: false, lunch: false },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      {
        time: '16:30',
        meeting: {
          id: 1002328221,
          date: '2024-10-07T16:30:00.000Z',
          name: 'Встреча Андрея',
          office: 'S4',
          canceled: true,
          activeMeeting: false,
          duration: 30,
          endTime: '2024-10-07T17:00:00.000Z',
        },
        lunch: false,
      },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
  {
    id: 178,
    firstName: 'Comagic (Технический)',
    lastName: 'Keep Calm',
    patronymic: null,
    email: 'comagic@forma.ru',
    password: '$2b$05$T/iuegybLMjJjgzt40ZNMeu7G4MJ3HB2PZ9Aw2eagzKg.NBBHCC8y',
    isActive: true,
    agency_id: '11',
    bitrix_id: 49,
    phone: '+7 999 111-22-33',
    redirect_url: null,
    isPartner: false,
    isSendMail: false,
    roles: [{ id: 2, value: 'manager', description: 'Роль для менеджеров' }],
    events: [
      {
        time: '08:00',
        meeting: {
          id: 1003167,
          date: '2024-10-07T08:00:00.000Z',
          name: 'Техническая встреча',
          office: 'S4',
          canceled: true,
          activeMeeting: false,
          duration: 30,
          endTime: '2024-10-07T08:30:00.000Z',
        },
        lunch: false,
      },
      { time: '08:30', meeting: false, lunch: false },
      { time: '09:00', meeting: false, lunch: false },
      { time: '09:30', meeting: false, lunch: false },
      { time: '10:00', meeting: false, lunch: false },
      { time: '10:30', meeting: false, lunch: false },
      { time: '11:00', meeting: false, lunch: false },
      { time: '11:30', meeting: false, lunch: false },
      { time: '12:00', meeting: false, lunch: false },
      { time: '12:30', meeting: false, lunch: false },
      { time: '13:00', meeting: false, lunch: false },
      { time: '13:30', meeting: false, lunch: false },
      { time: '14:00', meeting: false, lunch: false },
      {
        time: '14:30',
        meeting: {
          id: 1003171,
          date: '2024-10-07T14:30:00.000Z',
          name: 'Техническая встреча(2)',
          office: 'S4',
          canceled: false,
          activeMeeting: true,
          duration: 30,
          endTime: '2024-10-07T15:00:00.000Z',
        },
        lunch: false,
      },
      { time: '15:00', meeting: false, lunch: false },
      { time: '15:30', meeting: false, lunch: false },
      { time: '16:00', meeting: false, lunch: false },
      { time: '16:30', meeting: false, lunch: false },
      { time: '17:00', meeting: false, lunch: false },
      { time: '17:30', meeting: false, lunch: false },
      { time: '18:00', meeting: false, lunch: false },
      { time: '18:30', meeting: false, lunch: false },
      { time: '19:00', meeting: false, lunch: false },
      { time: '19:30', meeting: false, lunch: false },
      { time: '20:00', meeting: false, lunch: false },
      { time: '20:30', meeting: false, lunch: false },
      { time: '21:00', meeting: false, lunch: false },
      { time: '21:30', meeting: false, lunch: false },
    ],
  },
];

export const mockOperatingMode = {
  schedule: {
    userId: 178,
    schedules: [
      {
        projectId: 380,
        scheduleId: 1,
        workingDays: ['2024-09-12', '2024-09-10'],
        startTime: '12:00',
        endTime: '20:00',
      },
      {
        projectId: 1420,
        scheduleId: 2,
        workingDays: ['2024-09-15', '2024-09-27'],
        startTime: '12:00',
        endTime: '20:00',
      },
    ],
  },
};

export const mockVacationsManager = [
  {
    vacationId: 1,
    vacationDays: ['2024-08-01', '2024-08-14'],
  },
  {
    vacationId: 2,
    vacationDays: ['2024-09-01', '2024-09-14'],
  },
  {
    vacationId: 3,
    vacationDays: ['2024-10-01', '2024-10-14'],
  },
];
