import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';

const initialState = {
  activeMeeting: null,
  currentHeaderTitle: '',
  currentHeaderDate: null,
  isFlatCardAdvantagesShort: false,
  isShowCustomHeader: true,
  isShowSearchBar: false,
  searchBarValue: '',
  monitoringMeetings: [],
};

export const MeetingsSlice = createSlice({
  name: 'MeetingsData',
  initialState,
  reducers: {
    handleIsFlatCardAdvantagesShort: (state, action) => {
      state.isFlatCardAdvantagesShort = action.payload;
    },
    setActiveMeeting: (state, action) => {
      state.activeMeeting = action.payload;
    },
    setHeaderTitle: (state, action) => {
      state.currentHeaderTitle = action.payload;
    },
    setHeaderDate: (state, action) => {
      state.currentHeaderDate = action.payload;
    },
    setSearchBarValue: (state, action) => {
      state.searchBarValue = action.payload;
    },
    toggleHeader: (state, action) => {
      state.isShowCustomHeader = action.payload.isShowHeader;
    },
    toggleSearchBar: (state, action) => {
      state.isShowSearchBar = action.payload;
    },
    setMonitoringMeetings: (state, action) => {
      state.monitoringMeetings = action.payload;
    },
  },
});

export const meetingsApi = createApi({
  reducerPath: 'meetingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getMeetings: builder.query({
      query: () => apiRoutes.meetingsListApi,
      providesTags: [{ type: 'Meetings', id: 'MEET' }],
    }),
    getFilteredMeetings: builder.query({
      query: (search = '') => `${apiRoutes.meetingsListApi}?page=1&filter={"search":"${search}"}`,
      providesTags: [{ type: 'Meetings', id: 'MEET' }],
    }),
    updateMeetingData: builder.mutation({
      query: ({ id, data }) => ({
        url: `${apiRoutes.meetingApi}${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Meetings', id: 'MEET' }],
    }),
    updateSecondaryMeetingData: builder.mutation({
      query: ({ id, data }) => ({
        url: `${apiRoutes.secondaryMeetingsForMortgageManager}${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Meetings', id: 'MEET' }],
    }),
    finishMeeting: builder.mutation({
      query: ({ meetingId, data }) => ({
        url: `/api/meetings/${meetingId}/completion`,
        method: 'POST',
        body: data,
      }),
    }),
    getOfficeList: builder.query({
      query: () => apiRoutes.officeList,
    }),
    getProjectsList: builder.query({
      query: () => apiRoutes.projectsList,
    }),
    updateFakeMeeting: builder.mutation({
      query: ({ meetingId, data }) => ({
        url: `${apiRoutes.fakeMeeting.replace(':meetingId', meetingId)}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'Meetings', id: 'MEET' }],
    }),
    getMonitoringMeetings: builder.query({
      query: ({ page, dateFilter, projects, roles }) =>
        `${apiRoutes.meetingsMonitoringApi}?page=${page}&perPage=10&filter={"project":[${projects}],"manager":[]}&roles=[${roles}]&dateFilter=${dateFilter}`,
    }),
    getRoles: builder.query({
      query: () => `${apiRoutes.rolesMonitoringApi}?pagination={"perPage":100,"page":1}`,
    }),
  }),
});

export const {
  toggleHeader,
  toggleSearchBar,
  setHeaderTitle,
  setHeaderDate,
  handleIsFlatCardAdvantagesShort,
  setSearchBarValue,
  setActiveMeeting,
  setMonitoringMeetings,
} = MeetingsSlice.actions;

export default MeetingsSlice.reducer;

export const {
  useGetMeetingsQuery,
  useGetFilteredMeetingsQuery,
  useUpdateMeetingDataMutation,
  useUpdateSecondaryMeetingDataMutation,
  useFinishMeetingMutation,
  useGetOfficeListQuery,
  useGetProjectsListQuery,
  useUpdateFakeMeetingMutation,
  useGetMonitoringMeetingsQuery,
  useGetRolesQuery,
} = meetingsApi;
