import { useState, useEffect } from 'react';
import { Card, Typography, Box, InputAdornment, Stack } from '@mui/material';
import style from './Calculator.module.scss';
import { Colors } from '../../assets/mui-styles/Colors';
import { CustomSelect } from './ui/CustomSelect';
import { CustomInput } from './ui/CustomInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSwitch } from './ui/CustomSwitch';
import {
  NumberSumFormatCustom,
  PercentFormatCustom,
  NumberSumFormatDefault,
} from './ui/InputComponentsForInputWithSlider';
import { toFormat } from '../../utils/utils';

export const CalculatorForFree = ({
  benefits = [],
  currentBenefit,
  handleChangeBenefit,
  flatPrice,
  handleChangeFlatPrice,
  isPercent,
  firstPayment,
  handleChangeFirstPayment,
  handleChangeFirstPaymentCommitted,
  minFlatPrice,
  maxFlatPrice,
  fetchStatus,
  minFirstPayment,
  maxFirstPayment,
  term,
  handleChangeTerm,
  handleChangeTermCommitted,
  minTerm,
  maxTerm,
  handleSwitchValue,
  percentFirstPayment,
  handleChangePercentValue,
  priceWithMargin,
}) => {
  const [errorFlatPrice, setErrorFlatPrice] = useState('');
  const [errorFirstPayment, setErrorFirstPayment] = useState('');
  const [errorTerm, setErrorTerm] = useState('');

  useEffect(() => {
    if (maxFlatPrice < minFlatPrice || Number(flatPrice) === 0) {
      setErrorFlatPrice('Увеличьте стоимость квартиры');
    } else {
      setErrorFlatPrice('');
    }
  }, [maxFlatPrice, minFlatPrice, flatPrice]);

  useEffect(() => {
    if (firstPayment < minFirstPayment) {
      setErrorFirstPayment('Увеличьте сумму первого взноса');
    } else if (firstPayment > maxFirstPayment) {
      setErrorFirstPayment('Уменьшите сумму первого взноса');
    } else {
      setErrorFirstPayment('');
    }
  }, [firstPayment, maxFirstPayment, minFirstPayment]);

  useEffect(() => {
    if (term < minTerm) {
      setErrorTerm('Увеличьте срок кредита');
    } else if (term > maxTerm) {
      setErrorTerm('Уменьшите срок кредита');
    } else {
      setErrorTerm('');
    }
  }, [maxTerm, minTerm, term]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        background: Colors.additional,
        border: '1px solid',
        borderColor: Colors.superLightGrey,
        borderTopColor: 'transparent',
      }}
      className={style.calculatorBlockContainer}
    >
      <Typography variant='h3' className={style.title}>
        Рассчитать платеж
      </Typography>
      <Stack
        sx={{
          display: 'grid',
          gap: '24px',
          gridTemplateColumns: 'minmax(0, 1fr) 13vw',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {benefits?.length > 0 && (
            <CustomSelect
              label='Выберите программу'
              handleChangeCurrentItem={value => {
                handleChangeBenefit(value);
              }}
              currentItem={currentBenefit}
              items={benefits}
            />
          )}
          {currentBenefit && flatPrice && errorFlatPrice === '' ? (
            <CustomInputWithSlider
              helperText={errorFirstPayment}
              error={firstPayment < minFirstPayment || firstPayment > maxFirstPayment}
              onChangeInput={isPercent ? handleChangePercentValue : handleChangeFirstPaymentCommitted}
              onChangeSlider={handleChangeFirstPayment}
              valueSlider={firstPayment}
              valueInput={isPercent ? percentFirstPayment : firstPayment}
              label='Сумма первоначального взноса'
              min={minFirstPayment}
              max={maxFirstPayment}
              minValueText={
                isPercent
                  ? `${Math.round(((minFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                  : toFormat(minFirstPayment)
              }
              maxValueText={
                isPercent
                  ? `${Math.round(((maxFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                  : toFormat(maxFirstPayment)
              }
              inputComponent={isPercent ? PercentFormatCustom : NumberSumFormatCustom}
              endAdornment={
                <InputAdornment position='end'>
                  {isPercent ? toFormat(firstPayment) : `${percentFirstPayment} %`}
                </InputAdornment>
              }
              onChangeCommitted={handleChangeFirstPaymentCommitted}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {benefits?.length > 0 && (
            <CustomInput
              className={style.customInput}
              helperText={errorFlatPrice}
              error={maxFlatPrice < minFlatPrice || Number(flatPrice) === 0}
              valueInput={flatPrice}
              endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
              label='Стоимость квартиры'
              inputComponent={NumberSumFormatDefault}
              onChangeInput={handleChangeFlatPrice}
              fetchStatus={fetchStatus}
            />
          )}

          {currentBenefit && flatPrice && errorFlatPrice === '' ? (
            <CustomInputWithSlider
              helperText={errorTerm}
              error={term < minTerm || term > maxTerm}
              onChange={handleChangeTerm}
              onChangeCommitted={handleChangeTermCommitted}
              onChangeInput={handleChangeTermCommitted}
              value={term}
              label='Срок кредита'
              min={minTerm}
              max={maxTerm}
              minValueText={minTerm}
              maxValueText={maxTerm}
              inputType='number'
              fetchStatus={fetchStatus}
            />
          ) : null}
        </Box>
        {currentBenefit && flatPrice && errorFlatPrice === '' ? (
          <CustomSwitch
            onClick={handleSwitchValue}
            checked={isPercent}
            label='Сумма первого взноса, %'
            sx={{
              width: 'max-content',
              position: 'relative',
              right: '10px',
            }}
          />
        ) : null}
      </Stack>
    </Card>
  );
};
