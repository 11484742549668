import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BlockIds, DOMAIN_RELATIVE } from '../utils/constants';
import { clearUserData } from '../utils/utils';
import { apiRoutes, fetchProjectsByRole } from './api/api';
import ROUTES from './api/routes';
import { displayProjectsByRole } from '../utils/feature-flags';

const initialState = {
  projects: displayProjectsByRole
    ? []
    : [
        {
          id: 0,
          value: '0',
          title: 'Republic',
          url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=427`,
          active: false,
          blockId: BlockIds.REPUBLIC_BLOCK_ID,
        },
        {
          id: 1,
          value: '1',
          title: 'FØRST',
          url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=380`,
          active: true,
          blockId: BlockIds.FORST_BLOCK_ID,
        },
        {
          id: 2,
          value: '2',
          title: 'PORTLAND',
          url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1420`,
          active: false,
          blockId: BlockIds.PORTLAND_BLOCK_ID,
        },
        {
          id: 3,
          value: '3',
          title: 'SOUL',
          url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1246`,
          active: false,
          blockId: BlockIds.SOUL_BLOCK_ID,
        },
        {
          id: 4,
          value: '4',
          title: 'MOMENTS',
          url: `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=1457`,
          active: false,
          blockId: BlockIds.MOMENTS_BLOCK_ID,
        },
        {
          id: 5,
          value: '5',
          title: 'Porta',
          name: 'porta',
          active: false,
          blockId: BlockIds.PORTA_BLOCK_ID,
        },
      ],
  flats: [],
  bulks: [],
  bulksArr: [],
  houseArr: [],
  activeUrl: null,
  currFlat: null,
  currHouse: null,
  currBulk: null,
  currSection: null,
  defaultBenefits: [],
  benefits: [],
  currBenefit: {},
  newTerm: null,
  newTermPrice: null,
  warningBenefit: false,
  estimateModalStatus: false,
  isDataFromServerFetched: false,
  isBenefitsUpdated: false,
  benefitsUpdateTime: null,
  fetchStatus: 'load',
  fetchBenefitsStatus: 'load',
  mortgagesPrice: [],
  currBenefitPrice: null,
  currBenefitLot: null,
  initialPaymentPrice: [],
  initialPaymentLot: {},
  flatPrice: null,
  calcDataPrice: [],
  calcDataLot: {},
  sectionArr: [],
  currBenefitGroup: '',
  dataForFilter: {
    area: {},
    block: {},
    bulks: [],
    flats: [],
    price: {},
  },
  initialFlats: [],
  monthlyPaymentSubsidy: null,
  subsidyTerm: null,
  projectsListDetailed: [],
};

//получение всех квартир по выбранному проекту
export const fetchFlats = createAsyncThunk('cart/fetchFlats', async url => {
  const response = await fetch(`${url}`, {
    method: 'GET',
  });
  return await response.json();
});

export const fetchFlatsCSV = createAsyncThunk('cart/fetchFlatsCSV', async url => {
  const newURL = url.replace('landing/flat', 'flatcsv').replace('https://manager.forma.ru', DOMAIN_RELATIVE);
  const response = await fetch(`${newURL}`, {
    method: 'GET',
  });
  return await response.json();
});

export const fetchDataForFilter = createAsyncThunk('cart/fetchDataForFilter', async url => {
  const response = await fetch(`${url}`, {
    method: 'GET',
  });
  return await response.json();
});

export const fetchProjectsListDetailed = createAsyncThunk('projects/fetchProjectsListDetailed', async () => {
  const response = await fetch(apiRoutes.projectsListDetailed, {
    method: 'GET',
  });
  return await response.json();
});

//получение всех бенефитов для калькулятора по прайсу
export const fetchMortgagesPrice = createAsyncThunk('cart/fetchMortgagesPrice', async id => {
  const response = await fetch(`${DOMAIN_RELATIVE}/api/v2/flat/mortgage/list?blockId=${id}`, {
    method: 'GET',
  });
  return await response.json();
});

//получение минимального первого взноса для рассчета стоимости по прайс цене
export const fetchInitialPaymentPrice = createAsyncThunk(
  'cart/fetchInitialPaymentPrice',
  async ({ currBenefitPrice, flatPrice }) => {
    const method = '/api/v2/flat/mortgage';
    const response = await fetch(
      `${DOMAIN_RELATIVE}${method}/initialPaiment?mortgageId=${currBenefitPrice}&sum=${flatPrice}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    const result = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return result;
  },
);

//получение минимального первого взноса для рассчета стоимости по лоту
export const fetchInitialPaymentLot = createAsyncThunk(
  'cart/fetchInitialPaymentLot',
  async ({ currBenefitLot, flatPrice }) => {
    const response = await fetch(
      `${DOMAIN_RELATIVE}/api/flat/mortgage/initialPaiment?mortgageId=${currBenefitLot}&sum=${flatPrice}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    const result = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return result;
  },
);

// Определние страницы с PDF
export const isLotSelectionPage = () => () => {
  return window.location.href.includes(ROUTES.lotSelection);
};

//получение рассчета по введенным данным
export const fetchBenefits = createAsyncThunk(
  'cart/fetchBenefits',
  async ({ flatId, initialPayment, loanTerm, benefitId }) => {
    const response = await fetch(
      `https://api.forma.ru/v2/flat/mortgage?flatId=${flatId}&initialPayment=${initialPayment}&loanTerm=${loanTerm}&benefitId=${benefitId}`,
      {
        method: 'GET',
      },
    );
    return response.json();
  },
);

// получение рассчета по лоту
export const fetchCalcDataLot = createAsyncThunk(
  'cart/fetchBenefitsNew',
  async ({ loanTerm, mortgageId, initialPayment, sum }) => {
    const response = await fetch(
      `${DOMAIN_RELATIVE}/api/flat/mortgage?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const calcDataPrice = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return calcDataPrice;
  },
);

//получение рассчета по введенным данным прайс
export const fetchCalcDataPrice = createAsyncThunk(
  'cart/fetchCalcDataPrice',
  async ({ loanTerm, mortgageId, initialPayment, sum }) => {
    const method = '/api/v2/mortgage/flat';
    const response = await fetch(
      `${DOMAIN_RELATIVE}${method}?loanTerm=${loanTerm}&mortgageId=${mortgageId}&initialPayment=${initialPayment}&sum=${sum}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const calcDataPrice = await response.json();

    if (response.status === 401) {
      clearUserData();
    }

    return calcDataPrice;
  },
);

export const ProjectsSlice = createSlice({
  name: 'projectsInfo',
  initialState,
  reducers: {
    //изменение активного проекта
    changeActiveProject: (state, action) => {
      const newState = state.projects.map(item => {
        item.active = item.value === action.payload;
        return item;
      });

      state.projects = newState;
      state.defaultBenefits = [];
      state.benefits = [];
      state.newTerm = null;
    },

    //изменение активного дома
    chooseCurrHouse: (state, action) => {
      state.currHouse = action.payload;
    },

    //изменение активного корпуса
    chooseCurrBulk: (state, action) => {
      state.currBulk = action.payload;
    },

    //изменение активной секции
    chooseCurrSection: (state, action) => {
      state.currSection = action.payload;
    },

    //массив домов
    initHouseArr: (state, action) => {
      state.houseArr = action.payload;
    },

    //массив корпусов
    initBulksArr: (state, action) => {
      state.bulksArr = action.payload;
    },

    //массив секций
    initSectionArr: (state, action) => {
      state.sectionArr = action.payload;
    },

    //изменение выбранной квартиры
    chooseCurrFlat: (state, action) => {
      if (action.payload === null) {
        state.currFlat = null;
      } else if (state.flats.length > 0) {
        // Filter condition based on currHouse, currBulk, and currSection
        const currHouse = state.currHouse || sessionStorage.getItem('currHouse');
        const currBulk = state.currBulk || sessionStorage.getItem('currBulk');
        const currSection = state.currSection || sessionStorage.getItem('currSection');

        const flat = state.flats.find(item => {
          if (
            (!currHouse || item.bulk.houseName.toString() === currHouse) &&
            (!currBulk || item.bulk.name.toString() === currBulk) &&
            (!currSection || item.section.number.toString() === currSection)
          ) {
            return item.number.toString() === action.payload.toString();
          }
          return false;
        });
        if (!flat) {
          state.currFlat = null;
          return;
        }
        state.currFlat = flat;
        state.defaultBenefits = [];
        if (flat.type !== 'csv') {
          state.defaultBenefits = [
            ...flat.benefits.cash,
            ...flat.benefits.mortgage,
            ...flat.benefits.installment,
          ].filter(item => !item.name.toLowerCase().includes('тест'));
        }
        state.benefits = [];
        state.newTerm = null;
        state.currBenefit = {};
      } else {
        alert('Нет данных по квартирам');
      }
    },

    changeIsDataFromServerFetched: (state, action) => {
      state.isDataFromServerFetched = action.payload;
    },

    setIsBenefitsUpdated: (state, action) => {
      state.isBenefitsUpdated = action.payload;
    },

    setBenefitsUpdateTime: (state, action) => {
      state.benefitsUpdateTime = action.payload;
    },

    changeCurrBenefit: (state, action) => {
      if (Object.keys(action.payload).length === 0 && typeof action.payload !== 'number') {
        state.currBenefit = {};
      } else if (state.benefits.length > 0) {
        const newState = state.benefits.filter(item => {
          return item.id === action.payload;
        });
        const defaultState = state.defaultBenefits.filter(item => {
          return item.id === action.payload;
        });
        if (
          (newState[0]?.mortgage !== null && newState[0]?.group === 'mortgage') ||
          newState[0]?.group === 'installment' ||
          (newState[0]?.mortgage === null && newState[0]?.group === 'cash')
        ) {
          if (defaultState[0]?.group === 'installment') {
            state.currBenefit = defaultState[0];
          } else {
            state.currBenefit = newState[0];
          }
        } else if (defaultState[0]?.group === 'installment') {
          state.currBenefit = defaultState[0];
        } else {
          state.warningBenefit = true;
        }
      } else {
        const newState = state.defaultBenefits.filter(item => {
          return item.id === action.payload;
        });
        if (
          (newState[0]?.mortgage !== null && newState[0]?.group === 'mortgage') ||
          newState[0]?.group === 'installment' ||
          (newState[0]?.mortgage === null && newState[0]?.group === 'cash')
        ) {
          state.currBenefit = newState[0];
          state.newTerm = null;
        } else {
          state.warningBenefit = true;
        }
      }
    },

    changeWarningBenefit: (state, action) => {
      state.warningBenefit = action.payload;
    },

    changeCurrBenefitLot: (state, action) => {
      state.currBenefitLot = action.payload;
    },

    changeCurrBenefitPrice: (state, action) => {
      state.currBenefitPrice = action.payload;
    },

    changeCurrBenefitGroup: (state, action) => {
      state.currBenefitGroup = action.payload;
    },

    changeEstimateModalStatus: (state, action) => {
      state.estimateModalStatus = action.payload;
    },

    changeFlatPrice: (state, action) => {
      state.flatPrice = action.payload;
    },

    addNewTerm: (state, action) => {
      if (action.payload !== '') {
        state.newTerm = action.payload;
      } else {
        state.newTerm = '';
      }
    },

    addNewTermPrice: (state, action) => {
      if (action.payload !== '') {
        state.newTermPrice = action.payload;
      } else {
        state.newTermPrice = '';
      }
    },
    updateFlatsList: (state, action) => {
      state.flats = action.payload;
    },
    updateBulks: (state, action) => {
      state.bulks = action.payload;
    },
    updateInitialFlats: (state, action) => {
      state.initialFlats = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchProjectsByRole.pending, state => {
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchProjectsByRole.fulfilled, (state, action) => {
      state.projects = action.payload.projectsByRole
        .sort((a, b) => a.project_id - b.project_id)
        .map(({ name, project_id }, index) => {
          let active;

          if (sessionStorage.getItem('activeProject')) {
            active = String(index) === sessionStorage.getItem('activeProject');
          } else {
            active = project_id === BlockIds.FORST_BLOCK_ID;
          }

          return {
            id: index,
            value: `${index}`,
            title: name,
            name: name.toLowerCase(),
            url:
              project_id === BlockIds.PORTA_BLOCK_ID || project_id === BlockIds.FRAME_BLOCK_ID
                ? null
                : `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=${project_id}`,
            active,
            blockId: project_id,
          };
        });
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchProjectsByRole.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchFlats.fulfilled, (state, action) => {
      //фильтрация квартир по свободным + сортировка по номеру (по возрастанию)
      const filteredFlats = action.payload.flats
        .filter(flat => {
          return flat.status === 'free';
        })
        .sort((a, b) => {
          if (Number.parseFloat(a.number) > Number.parseFloat(b.number)) {
            return 1;
          }
          if (Number.parseFloat(a.number) < Number.parseFloat(b.number)) {
            return -1;
          }
          return 0;
        });
      state.flats = filteredFlats;
      state.initialFlats = filteredFlats;
      state.bulks = action.payload.bulks;
      state.bulks = action.payload.bulks;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchFlats.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchFlats.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchFlatsCSV.fulfilled, (state, action) => {
      //сортировка по номеру (по возрастанию)
      const sortedFlats = action.payload.flats.sort((a, b) => {
        if (Number.parseFloat(a.number) > Number.parseFloat(b.number)) {
          return 1;
        }
        if (Number.parseFloat(a.number) < Number.parseFloat(b.number)) {
          return -1;
        }
        return 0;
      });
      state.flats = sortedFlats;
      state.initialFlats = sortedFlats;
      state.bulks = action.payload.bulks;
      state.bulks = action.payload.bulks;

      state.fetchStatus = 'load';
    });
    builder.addCase(fetchFlatsCSV.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchFlatsCSV.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchDataForFilter.fulfilled, (state, action) => {
      state.fetchStatus = 'load';
      state.dataForFilter = action.payload;
    });
    builder.addCase(fetchDataForFilter.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchDataForFilter.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchBenefits.fulfilled, (state, action) => {
      state.benefits = [...action.payload.benefits.mortgage, ...action.payload.benefits.cash].filter(
        item => !item.name.toLowerCase().includes('тест'),
      );

      const benefit = action.payload.benefits.mortgage[0];

      if (benefit.group === 'mortgage' && benefit.mortgage === null) {
        state.warningBenefit = true;
      } else {
        state.currBenefit = benefit;
      }

      state.fetchStatus = 'load';
      state.fetchBenefitsStatus = 'load';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchBenefits.pending, state => {
      console.log('Загрузка');
      state.fetchBenefitsStatus = 'pending';
      state.estimateModalStatus = false;
    });
    builder.addCase(fetchBenefits.rejected, state => {
      console.log('Ошибка при запросе на сервер');

      state.fetchStatus = 'error';
      state.fetchBenefitsStatus = 'error';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchMortgagesPrice.fulfilled, (state, action) => {
      state.mortgagesPrice = action.payload;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchMortgagesPrice.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchMortgagesPrice.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchInitialPaymentPrice.fulfilled, (state, action) => {
      state.initialPaymentPrice = action.payload;
      if (action.payload.monthlyPaymentSubsidy && action.payload.subsidyTerm) {
        state.monthlyPaymentSubsidy = action.payload.monthlyPaymentSubsidy;
        state.subsidyTerm = action.payload.subsidyTerm;
      } else {
        state.monthlyPaymentSubsidy = null;
        state.subsidyTerm = null;
      }
      state.calcDataPrice = [];
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchInitialPaymentPrice.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchInitialPaymentPrice.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchInitialPaymentLot.fulfilled, (state, action) => {
      state.initialPaymentLot = action.payload;
      state.calcDataLot = {};
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchInitialPaymentLot.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchInitialPaymentLot.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
    builder.addCase(fetchCalcDataPrice.fulfilled, (state, action) => {
      state.calcDataPrice = action.payload;
      if (action.payload.monthlyPaymentSubsidy && action.payload.subsidyTerm) {
        state.monthlyPaymentSubsidy = action.payload.monthlyPaymentSubsidy;
        state.subsidyTerm = action.payload.subsidyTerm;
      } else {
        state.monthlyPaymentSubsidy = null;
        state.subsidyTerm = null;
      }
      state.fetchStatus = 'load';
      state.fetchBenefitsStatus = 'load';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchCalcDataPrice.pending, state => {
      console.log('Загрузка');
      state.fetchBenefitsStatus = 'pending';
      state.estimateModalStatus = false;
    });
    builder.addCase(fetchCalcDataPrice.rejected, state => {
      console.log('Ошибка при запросе на сервер');

      state.fetchStatus = 'error';
      state.fetchBenefitsStatus = 'error';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchCalcDataLot.fulfilled, (state, action) => {
      state.calcDataLot = action.payload;

      state.fetchStatus = 'load';
      state.fetchBenefitsStatus = 'load';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchCalcDataLot.pending, state => {
      console.log('Загрузка');
      state.fetchBenefitsStatus = 'pending';
      state.estimateModalStatus = false;
    });
    builder.addCase(fetchCalcDataLot.rejected, state => {
      console.log('Ошибка при запросе на сервер');

      state.fetchStatus = 'error';
      state.fetchBenefitsStatus = 'error';
      state.estimateModalStatus = true;
    });
    builder.addCase(fetchProjectsListDetailed.fulfilled, (state, action) => {
      state.projectsListDetailed = action.payload;
      state.fetchStatus = 'load';
    });
    builder.addCase(fetchProjectsListDetailed.pending, state => {
      console.log('Загрузка');
      state.fetchStatus = 'pending';
    });
    builder.addCase(fetchProjectsListDetailed.rejected, state => {
      console.log('Ошибка при запросе на сервер');
      state.fetchStatus = 'error';
    });
  },
});

export const {
  changeActiveProject,
  chooseCurrFlat,
  chooseCurrHouse,
  chooseCurrBulk,
  chooseCurrSection,
  changeCurrBenefit,
  addNewTerm,
  addNewTermPrice,
  changeWarningBenefit,
  changeEstimateModalStatus,
  initHouseArr,
  initBulksArr,
  initSectionArr,
  changeCurrBenefitLot,
  changeCurrBenefitPrice,
  changeFlatPrice,
  changeCurrBenefitGroup,
  updateFlatsList,
  updateBulks,
  updateInitialFlats,
  changeIsDataFromServerFetched,
  setIsBenefitsUpdated,
  setBenefitsUpdateTime,
} = ProjectsSlice.actions;

//УРЛ выбранного проекта
export const urlSelector = () => state => {
  return (
    state.projectsInfo.projects.find(item => {
      return item.active === true;
    })?.url || ''
  );
};

export const getActiveProject = () => state => {
  const activeProject = sessionStorage.getItem('activeProject');
  return (
    state.projectsInfo.projects.find(item => {
      return Number(item.value) === Number(activeProject);
    }) || {}
  );
};

//выбранный проект
export const projectSelector = () => state => {
  return (
    state.projectsInfo.projects.find(item => {
      return item.active === true;
    }) || {}
  );
};

//все квартиры
export const flatsSelector = () => state => {
  return state.projectsInfo.flats || [];
};

//выбранная квартира
export const currFlatSelector = () => state => {
  return state.projectsInfo.currFlat || null;
};

//выбранная секция
export const currSectionSelector = () => state => {
  return state.projectsInfo.currSection || null;
};

//выбранный дом
export const currHouseSelector = () => state => {
  return state.projectsInfo.currHouse || null;
};

//выбранный корпус
export const currBulkSelector = () => state => {
  return state.projectsInfo.currBulk || null;
};

//срок кредита
export const termSelector = () => state => {
  return state.projectsInfo.newTerm;
};

//срок кредита по прайс
export const termPriceSelector = () => state => {
  return state.projectsInfo.newTermPrice;
};

//предупреждение
export const warningBenefitSelector = () => state => {
  return state.projectsInfo.warningBenefit;
};

//статус модалки с подсчетами
export const estimateModalStatusSelector = () => state => {
  return state.projectsInfo.estimateModalStatus;
};

//статус модалки загрузки
export const fetchStatusSelector = () => state => {
  return state.projectsInfo.fetchStatus;
};

//статус белого фона на блоке с подсчетами
export const fetchBenefitsStatusSelector = () => state => {
  return state.projectsInfo.fetchBenefitsStatus;
};

//все корпуса
export const bulksSelector = () => state => {
  return state.projectsInfo.bulks || [];
};

//массив текущих корпусов
export const bulksArrSelector = () => state => {
  return state.projectsInfo.bulksArr || [];
};

//массив текущих секций
export const sectionArrSelector = () => state => {
  return state.projectsInfo.sectionArr || [];
};

//массив домов
export const houseArrSelector = () => state => {
  return state.projectsInfo.houseArr || [];
};

//массив льгот прайс
export const mortgagesPriceSelector = () => state => {
  return state.projectsInfo.mortgagesPrice || [];
};

//текущая программа в лот
export const currBenefitLotSelector = () => state => {
  return state.projectsInfo.currBenefitLot || null;
};

//текущая программа в прас
export const currBenefitPriceSelector = () => state => {
  return state.projectsInfo.currBenefitPrice || null;
};

//минимальный первый взнос для квартиры по прайсу
export const initialPaymentPriceSelector = () => state => {
  return state.projectsInfo.initialPaymentPrice || null;
};

//минимальный первый взнос для квартиры по лоту
export const initialPaymentLotSelector = () => state => {
  return state.projectsInfo.initialPaymentLot || null;
};

//прайсовая цена квартиры
export const flatPriceSelector = () => state => {
  return state.projectsInfo.flatPrice || null;
};

//данные после рассчетов по лоту квартиры
export const calcDataLotSelector = () => state => {
  return state.projectsInfo.calcDataLot || {};
};

//дата после рассчетов по прайс квартире
export const calcDataPriceSelector = () => state => {
  return state.projectsInfo.calcDataPrice || [];
};

//текущая выбранная группа бенефитов
export const currBenefitGroupSelector = state => {
  return state.projectsInfo.currBenefitGroup || '';
};
//все проекты
export const projectsSelector = () => state => {
  return state.projectsInfo.projects || [];
};

//текущая выбранная группа бенефитов
export const monthlyPaymentSubsidySelector = state => {
  return state.projectsInfo.monthlyPaymentSubsidy || null;
};

//текущая выбранная группа бенефитов
export const subsidyTermSelector = state => {
  return state.projectsInfo.subsidyTerm || null;
};

export const isDataFromServerFetchedSelector = state => {
  return state.projectsInfo.isDataFromServerFetched;
};

export const isBenefitsUpdatedSelector = state => {
  return state.projectsInfo.isBenefitsUpdated;
};

export const benefitsUpdateTimeSelector = state => {
  return state.projectsInfo.benefitsUpdateTime;
};

export default ProjectsSlice.reducer;
