import { Box, Container, Stack } from '@mui/material';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Colors } from '../../../assets/mui-styles/Colors';
import { deviceSelector } from '../../../entities/AuthSlice';
import Loader from '../../../shared/loader/Loader';
import ErrorBlock from '../../../widgets/error-block/ErrorBlock';
import { CalculatorTabs } from '../calculator-tabs/CalculatorTabs';
import { useSwitchCalculatorTab } from '../calculator-tabs/useSwitchCalculatorTab';
import { EstimationBlock } from '../estimation-block/EstimationBlock';
import style from './CalculatorBlock.module.scss';
import { isBenefitsUpdatedSelector } from '../../../entities/ProjectSlice';
import UpdateNotification from '../../../widgets/update-notification/UpdateNotification';

export const CalculatorBlock = ({
  propsForCalculatorByPrice,
  propsForCalculatorByLot,
  propsForCalculatorByFree,
  fetchStatus,
  statusSetters,
  uniqueId,
  meetingId,
  isEqualizer,
}) => {
  const { activeTab, handleChangeActiveTab } = useSwitchCalculatorTab(uniqueId, isEqualizer, meetingId);
  const deviceInfo = useSelector(deviceSelector());
  const isBenefitsUpdated = useSelector(isBenefitsUpdatedSelector);

  const { isDefaultCalculation } = propsForCalculatorByLot;

  const checkIfDisplayFreeEstimationBlock = () => {
    const {
      initialPayment,
      minFirstPayment,
      maxFirstPayment,
      currentBenefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
      flatPrice,
      calcData,
    } = propsForCalculatorByFree;

    const isMonthlyPaymentValid = Boolean(calcData?.monthlyPayment);
    const isPriceWithMarginPositive = initialPayment?.priceWithMargin > 0;
    const isMinFirstPaymentValid = minFirstPayment < maxFirstPayment;
    const isFlatPriceValid = Number(flatPrice) !== 0;
    const isBenefitExists = Boolean(currentBenefit);
    const isTermValid = term >= minTerm && term <= maxTerm;
    const isFirstPaymentCommittedValid =
      firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;

    const isCalculationByFreePriceValid =
      isMonthlyPaymentValid &&
      isPriceWithMarginPositive &&
      isBenefitExists &&
      isMinFirstPaymentValid &&
      isFlatPriceValid;

    return isCalculationByFreePriceValid && isTermValid && isFirstPaymentCommittedValid;
  };

  const checkIfDisplayPriceEstimationBlock = () => {
    const {
      minFirstPayment,
      maxFirstPayment,
      benefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
      flatPrice,
      calcData,
      initialPayment,
    } = propsForCalculatorByPrice;

    const isMonthlyPaymentExists = calcData?.monthlyPayment;
    const isPriceWithMarginExists = initialPayment?.priceWithMargin;
    const isMinMaxFirstPaymentValid = minFirstPayment < maxFirstPayment;
    const ifFlatPriceIsPositive = Number(flatPrice) !== 0;
    const isBenefitExists = Boolean(benefit);
    const isTermValid = term >= minTerm && term <= maxTerm;
    const isFirstPaymentCommittedValid =
      firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;
    const inCalculationValid =
      isMinMaxFirstPaymentValid &&
      ifFlatPriceIsPositive &&
      isBenefitExists &&
      isMonthlyPaymentExists &&
      isPriceWithMarginExists;

    return inCalculationValid && isTermValid && isFirstPaymentCommittedValid;
  };

  const checkIfDisplayLotEstimationBlock = () => {
    const {
      priceWithMargin,
      minFirstPayment,
      maxFirstPayment,
      benefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
    } = propsForCalculatorByLot;

    const doesBenefitExist = Boolean(benefit);
    const isTermInRange = term >= minTerm && term <= maxTerm;
    const isPaymentInRange = firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;
    const isPriceWithMarginPositive = priceWithMargin > 0;

    return doesBenefitExist && isTermInRange && isPaymentInRange && isPriceWithMarginPositive;
  };

  return (
    <>
      {fetchStatus === 'pending' && <Loader />}
      {fetchStatus === 'error' && <ErrorBlock />}

      <Container
        sx={{
          minWidth: '100%',
          backgroundColor: Colors.primary,
          minHeight: '100vh',
        }}
        className={style.container}
      >
        {isBenefitsUpdated && <UpdateNotification />}
        <Stack className={style.generalPageStack}>
          <CalculatorTabs
            meetingId={meetingId}
            propsForCalculatorByLot={propsForCalculatorByLot}
            propsForCalculatorByPrice={propsForCalculatorByPrice}
            propsForCalculatorByFree={propsForCalculatorByFree}
            statusSetters={statusSetters}
            handleChangeActiveTab={handleChangeActiveTab}
            activeTab={activeTab}
            isEqualizer={isEqualizer}
            fetchStatus={fetchStatus}
          />

          {deviceInfo.device !== 'isMobile' && (
            <>
              {checkIfDisplayLotEstimationBlock() && ((isEqualizer && !isDefaultCalculation) || !isEqualizer) && (
                <Box
                  sx={{ height: '100%' }}
                  className={cn({
                    [style.wrapperStealth]: activeTab !== 'lot',
                  })}
                >
                  <EstimationBlock
                    isEqualizer={isEqualizer}
                    {...propsForCalculatorByLot}
                    fetchStatus={fetchStatus}
                    meetingId={meetingId}
                    isLotTab
                  />
                </Box>
              )}

              {checkIfDisplayPriceEstimationBlock() && (
                <Box
                  sx={{ height: '100%' }}
                  className={cn({
                    [style.wrapperStealth]: activeTab !== 'price',
                  })}
                >
                  <EstimationBlock {...propsForCalculatorByPrice} fetchStatus={fetchStatus} meetingId={meetingId} />
                </Box>
              )}

              {checkIfDisplayFreeEstimationBlock() && (
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                  className={cn({
                    [style.wrapperStealth]: activeTab !== 'free',
                  })}
                >
                  <EstimationBlock {...propsForCalculatorByFree} fetchStatus={fetchStatus} isFreeTab={true} />
                </Box>
              )}
            </>
          )}
        </Stack>
      </Container>
    </>
  );
};
